import create, { GetState, SetState } from 'zustand';
import { combine } from 'zustand/middleware';
import { FormattedMessage } from 'react-intl';
import { nextApiRequest } from '../lib/utils';
import { useScopedState } from '../lib/state';
import {
  RegisterCompanyIdentificationForm,
  RegisterCompanyForm,
  RegisterCompanyIdentificationResponse,
  RegisterCompanyResponse,
  RegisterCompanySuccess,
} from '../../common/documents/RegisterCompanyDocument';
import { RegisterCompanySessionState } from '../common/Constants';
import { useUser } from './useUser';
import { validateSession } from './loginContext';
import { handleBasicErrors } from './basicErrorHandling';

export type RegisterCompanyState = {
  // Identification
  userFirstNames: string;
  userLastName: string;
  userBirthdate: string;
  isIdentified: boolean;

  // Manual identification
  userCountry: string;
  userPhone: string;
  userIdentificationConsent: boolean;
  isIdentificationFormSent: boolean;

  // Company link
  companyID: number | null;
  companyBusinessID: string;
  companyIdentificationConsent: boolean;
  isCompanyLinked: boolean;

  // Manual company link
  companyName: string;
  companyCountry: string;
  companyAddress: string;
  companySales: string;
  isCompanyLinkFormSent: boolean;

  // Session
  sessionCode: string;
  sessionStateID: RegisterCompanySessionState;
};

export const registerCompanyStateDefaults: RegisterCompanyState = {
  userFirstNames: '',
  userLastName: '',
  userBirthdate: '',
  isIdentified: false,
  userCountry: '',
  userPhone: '',
  userIdentificationConsent: false,
  isIdentificationFormSent: false,
  companyID: null,
  companyBusinessID: '',
  companyIdentificationConsent: false,
  isCompanyLinked: false,
  companyName: '',
  companyCountry: '',
  companyAddress: '',
  companySales: '',
  isCompanyLinkFormSent: false,
  sessionCode: '',
  sessionStateID: RegisterCompanySessionState.PENDING_AUTHENTICATION,
};

export type StateAction<T = {}, D = {}> = (
  set: SetState<RegisterCompanyState>,
  get: GetState<RegisterCompanyState>
) => (data?: T) => Promise<{ status: string; error?: string | JSX.Element } & D>;

/**
 * Clear the state
 */
export const doClearState: StateAction = (set, get) => async () => {
  set(registerCompanyStateDefaults);
  return { status: 'ok' };
};

/**
 * Start authentication process
 */
export const doIdentifyRedirect: StateAction<{}, { teliaFormUrl?: string }> = () => async () => {
  // Request an authentication URL from API
  const { status, teliaFormUrl } = await nextApiRequest(`/api/v1/www/auth/telia/pre-auth`);

  if (status !== 'ok') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  return { status: 'ok', teliaFormUrl };
};

/**
 * Authenticate the user with telia
 */
type IdentifyUserPayload = Pick<RegisterCompanyState, 'userFirstNames' | 'userLastName' | 'userBirthdate' | 'sessionCode'>;

export const doIdentifyUser: StateAction<IdentifyUserPayload> =
  (set) =>
  async ({ userFirstNames = '', userLastName = '', userBirthdate, sessionCode }) => {
    // Check that we got everything back from auth request
    if (!sessionCode) {
      return {
        status: 'bad request',
        error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
      };
    }

    // Update local account state
    await validateSession();

    // Clear old state && save to state
    set({
      ...registerCompanyStateDefaults,
      userFirstNames,
      userLastName,
      userBirthdate: userBirthdate || '',
      sessionCode,
      isIdentified: true,
      sessionStateID: RegisterCompanySessionState.PENDING_COMPANY,
    });

    return { status: 'ok' };
  };

/**
 * Validate company information on state
 */
export const doLinkCompany: StateAction = (set, get) => async () => {
  const { companyBusinessID, companyIdentificationConsent, sessionCode } = get();

  // Make sure all required information is supplied
  if (!sessionCode) {
    return {
      status: 'bad request',
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  // Check that consent checkbox has been checked
  if (!companyIdentificationConsent) {
    return {
      status: 'company consent missing',
      error: (
        <FormattedMessage id="register.company.companyConsentMissing" defaultMessage="Vakuus syötetyn tiedon aitoudesta on pakollinen." />
      ),
    };
  }

  // Check tax ID is in correct format
  if (!companyBusinessID || !/^[\d]{7}-[\d]$/.test(companyBusinessID.trim())) {
    return {
      status: 'invalid company tax id',
      error: (
        <FormattedMessage
          id="register.company.invalidCompanyTaxId"
          defaultMessage="Virheellinen Y-tunnus. Tarkista että Y-tunnus on syötetty oikein ja että se on oikeassa muodossa (1234567-8)."
        />
      ),
    };
  }

  // Try to register company to this account
  const { status, ...companyData } = (await nextApiRequest(
    `/api/v1/www/register-company/company-id`,
    {
      businessID: companyBusinessID.trim(),
      sessionCode,
    },
    { delay: 2000 }
  )) as RegisterCompanyResponse;

  const errorStatus = handleBasicErrors({ status });
  if (errorStatus) return errorStatus;

  if (status === 'unknown company') {
    return {
      status,
      error: (
        <FormattedMessage
          id="register.company.companyTaxIdUnknown"
          defaultMessage="Y-tunnusta ei vielä löydy Constlen tietokannasta. Voit pyytää sen lisäämistä lomakkeen avulla."
        />
      ),
    };
  }

  if (status === 'already registered') {
    return {
      status,
      error: (
        <FormattedMessage
          id="register.company.companyTaxIdExists"
          defaultMessage="Y-tunnus on jo rekisteröity Constleen. Ole hyvä ja kirjaudu sisään. Jos olet unohtanut tunnuksesi, ota yhteyttä tukeen."
        />
      ),
    };
  }

  if (status !== 'ok') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  const { companyID, companyName, sessionStateID } = companyData as RegisterCompanySuccess;

  // Save to state
  set({ companyID, companyBusinessID, companyName, isCompanyLinked: true, sessionStateID });

  // Update local account state
  await validateSession();

  return { status: 'ok' };
};

export const doSendIdentificationForm: StateAction = (set, get) => async () => {
  const { userFirstNames, userLastName, userBirthdate, userCountry, userPhone, userIdentificationConsent } = get();

  // Check that all information is filled in
  if (!userFirstNames || !userLastName || !userBirthdate || !userCountry || !userPhone) {
    return {
      status: 'bad request',
      error: (
        <FormattedMessage
          id="register.company.identificationFormInformationMissing"
          defaultMessage="Täytä kaikki kentät ennen lomakkeen lähettämistä."
        />
      ),
    };
  }

  // Check that consent checkbox has been checked
  if (!userIdentificationConsent) {
    return {
      status: 'identification consent missing',
      error: (
        <FormattedMessage
          id="register.company.identificationFormConsentMissing"
          defaultMessage="Vakuus syötetyn tiedon aitoudesta on pakollinen."
        />
      ),
    };
  }

  const identificationRequestPayload: RegisterCompanyIdentificationForm = {
    firstNames: userFirstNames,
    lastName: userLastName,
    birthdate: userBirthdate,
    country: userCountry,
    phone: userPhone,
  };

  // Authenticate with the manual form
  const { status, ...identificationData } = (await nextApiRequest(
    `/api/v1/www/register-company/identification-form`,
    identificationRequestPayload,
    { delay: 2000 }
  )) as RegisterCompanyIdentificationResponse;

  const errorStatus = handleBasicErrors({ status });
  if (errorStatus) return errorStatus;

  if (status !== 'ok') {
    return {
      status: 'identification failed',
      error: (
        <FormattedMessage
          id="register.company.identificationFormFailed"
          defaultMessage="Tunnistautuminen lomakkeella epäonnistui. Ole hyvä ja yritä uudelleen…"
        />
      ),
    };
  }

  const { sessionCode } = identificationData;

  // Update local account state
  await validateSession();

  // Save to state
  set({
    isIdentificationFormSent: true,
    sessionCode,
    sessionStateID: RegisterCompanySessionState.PENDING_COMPANY,
  });

  return { status: 'ok' };
};

export const doSendCompanyForm: StateAction = (set, get) => async () => {
  const { companyBusinessID, companyIdentificationConsent, companyName, companyCountry, companyAddress, companySales, sessionCode } = get();

  // Check that all information is filled in
  if (!companyBusinessID || !companyName || !companyCountry || !companyAddress || !companySales) {
    return {
      status: 'bad request',
      error: (
        <FormattedMessage
          id="register.company.companyFormInformationMissing"
          defaultMessage="Täytä kaikki kentät ennen lomakkeen lähettämistä."
        />
      ),
    };
  }

  // Check that consent checkbox has been checked
  if (!companyIdentificationConsent) {
    return {
      status: 'company consent missing',
      error: (
        <FormattedMessage
          id="register.company.companyFormConsentMissing"
          defaultMessage="Vakuus syötetyn tiedon aitoudesta on pakollinen."
        />
      ),
    };
  }

  const companyRequestPayload: RegisterCompanyForm = {
    businessID: companyBusinessID,
    name: companyName,
    country: companyCountry,
    address: companyAddress,
    sales: companySales,
    sessionCode,
  };

  // Send manual company form
  const { status, ...companyData } = (await nextApiRequest(`/api/v1/www/register-company/company-form`, companyRequestPayload, {
    delay: 2000,
  })) as RegisterCompanyResponse;

  const errorStatus = handleBasicErrors({ status });
  if (errorStatus) return errorStatus;

  if (status !== 'ok') {
    return {
      status: 'company form failed',
      error: (
        <FormattedMessage
          id="register.company.companyFormFailed"
          defaultMessage="Yrityksen liittäminen lomakkeella epäonnistui. Ole hyvä ja yritä uudelleen…"
        />
      ),
    };
  }

  const { sessionStateID } = companyData as RegisterCompanySuccess;

  // Save to state
  set({
    isCompanyLinkFormSent: true,
    sessionStateID,
  });

  // Update local account state
  await validateSession();

  return { status: 'ok' };
};

/** Store */
export const registerCompanyState = create(
  combine(registerCompanyStateDefaults, (set, get) => ({
    /** State methods */
    doClearState: doClearState(set, get),
    doIdentifyRedirect: doIdentifyRedirect(set, get),
    doIdentifyUser: doIdentifyUser(set, get),
    doLinkCompany: doLinkCompany(set, get),
    doSendIdentificationForm: doSendIdentificationForm(set, get),
    doSendCompanyForm: doSendCompanyForm(set, get),

    /** General set state */
    set,
  }))
);

/** State hook */
export const useRegisterCompanyState = registerCompanyState;

/** All possbile link company steps */
export const registerCompanySteps = [
  'review',
  'identification',
  'manual-identification',
  'link',
  'manual-link',
  'complete',
  'manual-complete',
  'login',
] as const;

/** Hook to get current link company step programmatically */
export const useRegisterCompanyStep = (currentStep: typeof registerCompanySteps[number]): typeof registerCompanySteps[number] => {
  const { isLoggedIn, companies, isSSRRender } = useUser();
  const { sessionStateID, set } = useScopedState(registerCompanyState, ['sessionStateID']);

  // If the user has not logged in, redirect to login
  if (!isSSRRender && !isLoggedIn) return 'login';

  // Fallback to review step if user tries to go forward with multiple companies
  // TODO: Remove this step when multiple companies are implemented
  if (companies.length > 0 && !['complete', 'manual-complete'].includes(currentStep)) return 'review';

  // If there are companies already linked to this account, show "review" step first
  if (companies.length > 0 && sessionStateID === RegisterCompanySessionState.PENDING_AUTHENTICATION) {
    return ['manual-identification', 'identification'].includes(currentStep) ? currentStep : 'review';
  }

  // If user has not identified, show the identification step
  if (sessionStateID === RegisterCompanySessionState.PENDING_AUTHENTICATION) {
    return currentStep === 'manual-identification' ? 'manual-identification' : 'identification';
  }

  // If company is not linked, show the link step
  if (sessionStateID === RegisterCompanySessionState.PENDING_COMPANY) {
    // Clear the consent box when navigating to manual company form
    if (currentStep === 'manual-link') {
      set({ companyIdentificationConsent: false });
    }

    return currentStep === 'manual-link' ? 'manual-link' : 'link';
  }

  // If manual forms were used, show the manual complete screen
  if (sessionStateID === RegisterCompanySessionState.PENDING_REVIEW) {
    return 'manual-complete';
  }

  // If automated methods were used, show the "instant" complete screen
  if (sessionStateID === RegisterCompanySessionState.ACCEPTED) {
    return 'complete';
  }

  // Fallback to identification if something is off...
  return 'identification';
};
