import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import Typography from '@material-ui/core/Typography';
import { useScopedState } from '../../lib/state';
import { registerCompanyState } from '../../context/registerCompanyStore';
import { Header } from '../../components/Header';
import { Color, cardStyles, navbarHeight, mediaQuery } from '../../components/Theme';
import { Button } from '../Button';
import { Error } from '../Error';
import { BannerBox } from '../Banner';

export const Page = styled.div`
  padding: calc(2rem + ${navbarHeight}px) 0 2rem 0;
  min-height: 100vh;
  background-color: ${Color.secondary};
  // https://unsplash.com/photos/VLPUm5wP5Z0
  background-image: linear-gradient(to right, ${transparentize(0.4, Color.secondary)}, ${transparentize(0.8, Color.secondary)}),
    url(/constle_hero_background.jpg);
  background-position: center 20%;
  background-size: cover;
  box-sizing: border-box;

  ${mediaQuery.small(css`
    padding: ${navbarHeight}px 0 0 0;
    min-height: auto;
    background-image: none;
    background-color: ${Color.background};
  `)}
`;

const Content = styled.div<{ footer: boolean; $width?: number }>`
  ${cardStyles}
  margin-bottom: 1.5rem;
  padding: 2.5rem;
  max-width: ${(props) => props.$width || 600}px;

  ${({ footer }) =>
    footer &&
    css`
      margin-bottom: 1.5rem;
    `}

  ${mediaQuery.small(css`
    margin: 0;
    padding: 2rem;
    max-width: 100%;
    width: 100%;
    border-bottom: 2px solid ${Color.background};
    border-radius: 0px;
  `)}

  .MuiStepper-root {
    padding: 2.5rem 0 0.8rem 0;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 1rem;
  }

  ${BannerBox} {
    &:not(.error) {
      margin-bottom: 2rem;
    }

    &.error {
      margin-top: 1rem;
    }
  }

  .MuiStepLabel-label {
    font-weight: 500;
    color: ${Color.black};
  }

  .MuiStepContent-root {
    padding-left: 1.75rem;
    .MuiTypography-body2 {
      opacity: 0.8;
    }
  }

  .MuiSvgIcon-root text {
    font-weight: bold;
    fill: ${Color.white};
  }

  > .MuiTypography-body2 {
    margin-bottom: 2rem;
    opacity: 0.8;
  }

  hr {
    margin-top: 2rem;
    margin-left: -2.5rem;
    width: calc(100% + 5rem);
    height: 2px;
    background-color: ${Color.background};
    border: none;
  }
`;

const ContentMain = styled.div`
  .MuiFormControl-root:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const Control = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0 0;
`;

const Footer = styled.div<{ $width?: number }>`
  ${cardStyles}
  padding: 0 2.5rem 0 2.5rem;
  max-width: ${(props) => props.$width || 600}px;
  background-color: transparent;
  box-shadow: none;

  ${mediaQuery.small(css`
    margin: 0;
    padding: 2rem;
    width: 100%;
    max-width: none;
    background-color: ${Color.white};
    border-radius: 0;
  `)}
`;

export interface AuthLayoutProps {
  title: string | JSX.Element;
  icon?: JSX.Element;
  description: string | JSX.Element;
  primaryAction?: string | JSX.Element | false;
  secondaryAction?: string | JSX.Element;
  stepper?: JSX.Element | null;
  control?: JSX.Element | null | false;
  footer?: JSX.Element | null;
  error?: string | JSX.Element;
  errorElement?: JSX.Element | null;

  /** Should the dialog go into "disabled" state after successful action */
  disableAfterSuccess?: boolean;

  /** Used on the login page to make the dialog thinner */
  width?: number;

  onValidateStep?: () => Promise<true | string | JSX.Element>;
  onCompleteStep?: () => void;
  onCancelStep?: () => void;
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  icon,
  description,
  primaryAction = 'Seuraava',
  secondaryAction = 'Keskeytä',
  stepper = null,
  control = null,
  footer = null,
  error: externalError = null,
  errorElement = null,
  disableAfterSuccess = false,
  width,
  onValidateStep,
  onCompleteStep,
  onCancelStep,
  children,
}) => {
  const router = useRouter();

  const { doClearState: doClearRegisterCompanyState } = useScopedState(registerCompanyState, []);

  const [error, setError] = useState<string | JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleValidate = async () => {
    setIsLoading(true);

    if (onValidateStep) {
      const state = await onValidateStep();
      if (state === true) {
        handleNext();
      } else {
        setError(state);
        setIsLoading(false);
        return;
      }
    } else {
      handleNext();
    }

    if (disableAfterSuccess) {
      setError(null);
      setIsLoading(false);
      setIsDisabled(true);
    }
  };

  const handleNext = async () => {
    if (onCompleteStep) return onCompleteStep();
  };

  const handleCancelButtonClick = async () => {
    if (onCancelStep) return onCancelStep();

    await doClearRegisterCompanyState();

    router.push('/');
  };

  const handleNextButtonClick = async () => {
    await handleValidate();
  };

  return (
    <Page>
      <Content footer={!!footer} $width={width}>
        <Header icon={icon}>{title}</Header>
        <Typography variant="body2">{description}</Typography>

        <ContentMain>{children}</ContentMain>

        {errorElement ? errorElement : <Error error={externalError ?? error} />}

        {!control && control !== false && (
          <Control>
            <Button variant="text" color="default" disabled={isLoading || isDisabled} onClick={handleCancelButtonClick}>
              {secondaryAction}
            </Button>
            {primaryAction !== false && (
              <Button loading={isLoading} disabled={isLoading || isDisabled} onClick={handleNextButtonClick}>
                {primaryAction}
              </Button>
            )}
          </Control>
        )}

        {!!control && <Control>{control}</Control>}

        {stepper !== null && (
          <>
            <hr />

            {stepper}
          </>
        )}
      </Content>

      {!!footer && <Footer $width={width}>{footer}</Footer>}
    </Page>
  );
};
