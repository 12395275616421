import React from 'react';
import { FormattedMessage } from 'react-intl';

export const handleBasicErrors = ({ status, catchAll = false }: { status: string; catchAll?: boolean }) => {
  if (status === 'bad request') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  if (status === 'rate limit') {
    return {
      status,
      error: (
        <FormattedMessage
          id="error.rateLimit"
          defaultMessage="Tietokanta on erityisen kiireinen. Odota muutama minuutti ja yritä uudelleen…"
        />
      ),
    };
  }

  if (catchAll) {
    if (status !== 'ok') {
      return {
        status,
        error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
      };
    }
  }

  return false;
};
